import ClientOnlyPortal from '@components/ClientOnlyPortal/ClientOnlyPortal'
import { CloseIcon } from '@components/UI/Icons/close'
import { Button } from '@components/common/UI/Button'
import { ICMLXTeaser } from '@typesApp/cmsPlacement/CMLXTeaser'
import clsx from 'clsx'
import { CSSProperties, useState } from 'react'
import { CmsCta } from '../CmsCta'
import { CmsMedia } from '../CmsMedia'
import { ImageCrops } from '../CmsMedia/types'
import style from './styles/index.module.scss'
import HtmlRenderer from '@components/UI/HtmlRender'

type CmsCtaModal = {
  text: string
  ctaStyle: CSSProperties
  teaser?: ICMLXTeaser
}

const imageCrops: ImageCrops = {
  xxl: {
    crop: 'landscape_ratio40x13',
    width: 2880,
    height: 936,
  },
  xl: {
    crop: 'landscape_ratio40x13',
    width: 2560,
    height: 936,
  },
  lg: {
    crop: 'landscape_ratio32x13',
    width: 1843,
    height: 749,
  },
  md: {
    crop: 'landscape_ratio192x71',
    width: 1382,
    height: 511,
  },
  sm: {
    crop: 'landscape_ratio192x71',
    width: 1382,
    height: 511,
  },
}

const CmsCtaModal = ({ text, ctaStyle, teaser }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const { teaserLXCallToActionSettings, teaserTitle4, teaserText2 } = teaser
  const handleCloseModal = () => {
    setIsMounted(false)
  }

  const handleOpenModal = () => {
    setShowModal(true)
    setIsMounted(true)
  }

  const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }

  const handleAnimationEnd = () => {
    if (!isMounted) setShowModal(false)
  }

  return (
    <>
      <Button {...ctaStyle} onClick={handleOpenModal}>
        {text}
      </Button>
      {showModal && (
        <ClientOnlyPortal selector={'#vmPortal'}>
          <div
            className={clsx(style.overlay, { [style.mounting]: isMounted, [style.unmounting]: !isMounted })}
            onClick={handleCloseModal}
            onAnimationEnd={handleAnimationEnd}
          >
            <div className={style.modal}>
              <button className={style.close} aria-label="close" onClick={handleCloseModal}>
                <CloseIcon />
              </button>
              <h1 className={style['title']}>{teaserTitle4}</h1>
              {teaser?.media && <CmsMedia media={teaser?.media} imageCrops={imageCrops} alt={teaser?.name} />}
              <div className={style.body}>
                <HtmlRenderer htmlString={teaserText2} variant="body1" />
              </div>
              {teaserLXCallToActionSettings &&
                teaserLXCallToActionSettings.map((actionSettings, index) => (
                  <div
                    className={style['button-container']}
                    key={`${actionSettings.callToActionText}_${index}`}
                    onClick={stopPropagation}
                  >
                    <CmsCta teaserCtaSetting={actionSettings} />
                  </div>
                ))}
            </div>
          </div>
        </ClientOnlyPortal>
      )}
    </>
  )
}

export default CmsCtaModal
